<template>
  <div class="body-bg">
    <van-nav-bar
      title="钱包"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    <div style="height: 4px"></div>
    <div class="container">
      <div class="wallet-box">
        <div class="account-box">
          <p>账户余额(元)</p>
          <p class="account">{{ fmoney(userBalance,2) }}</p>
        </div>

        <div class="statictis-box">
          <div class="statictis-content">
            <div class="item-box-1">
              <p>代办累计(元):</p>
              <p class="money spaceL">{{ fmoney(staff_allmoney,2) }}</p>
            </div>
            <div class="item-box-2">
              <p>邀请累计(元):</p>
              <p class="money spaceL">0.00</p>
            </div>
          </div>
        </div>

        <div class="action-bar">
          <van-button round block type="info" color="#F3C301" @click="toCrash">
            <p style="color:#333">提现</p></van-button
          >
        </div>
      </div>
    </div>
    <div class="kbox-gray5"></div>
    <div class="container">
      <div class="trade-box">
        <van-cell-group :border="true">
          <van-cell :border="false">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <div class="v-center">
                <!--
                <van-icon
                  slot="item-icon-active"
                  class="iconfont"
                  class-prefix="icon"
                  name="order-doing"
                />
                -->
                <span class="t-title">提现记录</span>
              </div>
            </template>
          </van-cell>
          <div class="date-tool">
            <div class="filter" @click="showDateFilterPopup">
              <span>{{ date }}</span>
              <van-icon class="spaceL" name="arrow-down" />
            </div>
            <div class="total-sts">

              <div class="sts">
                <div class="item">
                   <p class="title">累计提现</p>
                <p class="txt spaceL">￥{{ fmoney(cash_out_allMoney,2) }}</p>
                </div>
                <div class="item">
                    <p class="title">累计收入</p>
                <p class="txt spaceL">￥{{ fmoney(staff_allmoney,2) }}</p>
                </div>
              </div>
              <div class="sts">
                <div class="item">
                    <p class="title">当月累计提现</p>
                <p class="txt spaceL">￥{{ fmoney(cash_out_thisMonth,2) }}</p>
                </div>
                <div class="item">
                    <p class="title">当月累计收入</p>
                <p class="txt spaceL">￥{{ fmoney(staff_money_thisMonth,2) }}</p>
                </div>
              </div>
              <!--
              <div class="out spaceR">
                <p class="title">累计提现</p>
                <p class="txt spaceL">￥{{ fmoney(cash_out_allMoney,2) }}</p>
              </div>
              
              <div class="in spaceL">
                <p class="title">累计收入</p>
                <p class="txt spaceL">￥{{ fmoney(staff_allmoney,2) }}</p>
              </div>
              <div class="out spaceR">
                <p class="title">当月累计提现</p>
                <p class="txt spaceL">￥{{ fmoney(cash_out_thisMonth,2) }}</p>
              </div>
              
              <div class="in spaceL">
                <p class="title">当月累计收入</p>
                <p class="txt spaceL">￥{{ fmoney(staff_money_thisMonth,2) }}</p>
              </div>
              -->
            </div>
          </div>
          <van-cell v-for="(item, index) in list" :key="index">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <div class="trade-item-box">
                <div class="item-text">
                  <div class="text-box spaceR">
                    <p v-if="item.type==1" class="txt1">提现</p>
                    <p v-if="item.type==2" class="txt1">收入</p>
                    <p v-if="item.type==2" class="txt2">订单编号：{{ item.order_no }}</p>
                    <p v-if="item.type==1" class="txt2">&nbsp;</p>
                  </div>
                  
                  <div class="date-box">
                    <p class="date">{{ formateDateStr(item.create_time) }}</p>
                
                  </div>
                
                </div>
                <div class="item-price">
                  <div v-if="item.type==2" class="income">+￥{{ fmoney(item.money,2) }}</div>
                  <div v-if="item.type==1" class="income">-￥{{ fmoney(item.money,2) }}</div>
                  <div class="excess">余额: ￥{{ fmoney(item.balance,2) }}</div>
                </div>
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <van-popup
          v-model="showDateFilter"
          position="bottom"
          round
          style="height: 240px"
        >
          <van-datetime-picker
            v-model="currentDate"
            type="year-month"
            title="选择年月"
            :formatter="formatter"
            @confirm="confirmFilterDate"
            @cancel="closeDateFilterPopup"
          />
        </van-popup>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import UserService from "@/api/user";
import { Toast } from "vant";
export default {
  data() {
    return {
      showDateFilter: false,
      date: moment(new Date()).format("YYYY-MM"),
      userBalance: 0,
      //累计收入
      staff_allmoney: 0,
      //当月累计收入
      staff_money_thisMonth: 0,
      currentDate: new Date(),
      list: null,
      //累计提现金额
      cash_out_allMoney: 0,
      //当月累计提现金额
      cash_out_thisMonth: 0,
    };
  },
  async beforeCreate() {
    //得到用户统计信息
    var token = this.$cookie.get("token");
    //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
    var params = {
      token: token,
    };
    const resp1 = await UserService.staffInfo(this, params);
    if (resp1.data.code == 10000) {
      this.userBalance = resp1.data.data.staff_balance;
      //代办累计，总订单收入
      this.staff_allmoney = parseFloat(resp1.data.data.staff_allmoney);
      console.log()
    }
    this.getCashOutList();
  },
  methods: {
    async getCashOutList() {
      var token = this.$cookie.get("token");
      console.log(this.date);
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      var params = {
        token: token,
        time: this.date,
      };
      const resp1 = await UserService.getCashOutList(this, params);
      if (resp1.data.code == 10000) {
        this.list = resp1.data.data.list;
        //总的累计提现金额
        this.cash_out_allMoney = parseFloat(resp1.data.data.cash_out_allMoney).toFixed(2);
        this.cash_out_thisMonth = parseFloat(resp1.data.data.cash_out_thisMonth).toFixed(2);
        this.staff_money_thisMonth = parseFloat(resp1.data.data.staff_money_thisMonth).toFixed(2);
        
        console.log(resp1.data.data);
      }
    },

    confirmFilterDate: function(value) {
      this.date = moment(value).format("YYYY-MM");
      this.getCashOutList();
      this.closeDateFilterPopup();
    },
    showDateFilterPopup: function() {
      this.showDateFilter = true;
    },
    closeDateFilterPopup: function() {
      this.showDateFilter = false;
    },
    goHome: function() {
      this.$router.push("/");
    },
    goBack: function() {
      this.$router.go(-1);
    },
    toCrash: function() {
      if (this.userBalance < 1) {
        Toast("金额太小无法提现");
        return;
      }
      this.$router.push("/person/wallet/crash");
    },
    formateDateStr(str){
      return str.substring(5,10);
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    fmoney(s, n) {
      n = n > 0 && n <= 20 ? n : 2;
      s = parseFloat((s + "").replace(/[^\d\\.-]/g, "")).toFixed(n) + "";
      var l = s
          .split(".")[0]
          .split("")
          .reverse(),
        r = s.split(".")[1];
      var t = "";
      for ( var i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
      }
      return (
        t
          .split("")
          .reverse()
          .join("") +
        "." +
        r
      );
    },
  },
};
</script>

<style scoped>
.t-title {
  color: #333;
  font-weight: 800;
  font-size: 16px;
}
.body-bg {
  background-color: #fafafa;
}
.container {
  width: 100%;
}

.wallet-box {
  padding: 20px 10px 10px 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.wallet-box .account-box {
  margin: 10px auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.account-box p {
  color: #333;
  font-size: 13px;
  margin-bottom: 10px;
}
.account-box .account {
  color: #333;
  font-size: 35px;
  font-weight: 600;
}
.wallet-box .statictis-box {
  width: 100%;
}
.statictis-box .statictis-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.statictis-content .item-box-1 {
  width: 50%;
  font-size: 13px;
  color: #333;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.statictis-content .item-box-2 {
  width: 50%;
  font-size: 13px;
  color: #333;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.item-box-1 .money,
.item-box-2 .money {
  font-size: 13px;
  color: #333;
  font-weight: 600;
}
.trade-box .date-tool {
  background: #f5f5f5;
  font-size: 13px;
  padding: 0 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.date-tool .total-sts {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  min-width: 260px;
  padding-top: 4px;
  padding-bottom: 6px;
}
.total-sts .sts {
   display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.total-sts .sts .item{
   display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  font-size: 10px;
  font-weight: 600;
}
.total-sts .in,
.total-sts .out {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}
.in .title,
.out .title {
  color: #b3b3b3;
  font-size: 12px;

}
.in .txt,
.out .txt {
  color: #333;
  font-size: 12px;
  font-weight: 600;
}
.trade-box .trade-item-box {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.trade-item-box .item-text {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.item-text .date-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 12px;
  height: 48px;
}
.item-text .text-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.text-box .txt2 {
  color: #333;
  font-size: 12px;
  min-width: 180px;
}
.text-box .txt1 {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.trade-item-box .item-price {
  color: #333;
  font-size: 14px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.trade-item-box .item-price .income {
  font-weight: 700;
}

.trade-item-box .item-price .excess {
  font-size: 10px;
  color: #808080;
  font-weight: 600;
}

.van-dropdown-menu {
  display: inline-block !important;
}
.van-button--default {
  border: solid 1px #ffffff;
}
.action-bar {
  width: 80%;
  margin-top: 40px;
  margin-bottom: 20px;
}
</style>
